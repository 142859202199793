export const networkNames = {
  LOCAL: "LOCAL",
  RINKEBY: "RINKEBY",
  ROPSTEN: "ROPSTEN",
  KOVAN: "KOVAN",
  MAINNET: "MAINNET",
  POLYGON: "POLYGON",
  GOERLI: "GOERLI",
  OPTIMISM: "OPTIMISM",
  ARBITRUM: "ARBITRUM",
};

export const chainIds = {
  MAINNET: 1,
  ROPSTEN: 3,
  RINKEBY: 4,
  GOERLI: 5,
  OPTIMISM: 10,
  KOVAN: 42,
  POLYGON: 137,
  ARBITRUM: 42161,
};

export const RPC_URLS = {
  1: `https://mainnet.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_TOKEN}`,
  4: `https://rinkeby.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_TOKEN}`,
  5: `https://goerli.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_TOKEN}`,
  10: `https://optimism-mainnet.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_TOKEN}`,
  137: `https://polygon-mainnet.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_TOKEN}`,
  42161: `https://arbitrum-mainnet.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_TOKEN}`,
};

export function findNetworkNameByWeb3ChainId(chainId) {
  switch (chainId) {
    case chainIds.MAINNET:
      return networkNames.MAINNET;
    case chainIds.POLYGON:
      return networkNames.POLYGON;
    case chainIds.ROPSTEN:
      return networkNames.ROPSTEN;
    case chainIds.RINKEBY:
      return networkNames.RINKEBY;
    case chainIds.KOVAN:
      return networkNames.KOVAN;
    case chainIds.GOERLI:
      return networkNames.GOERLI;
    case chainIds.OPTIMISM:
        return networkNames.OPTIMISM;
    case chainIds.ARBITRUM:
        return networkNames.ARBITRUM;
    default:
      return "Unknown Network";
  }
}

export function getGnosisURL(chainId) {
  switch (chainId) {

      case chainIds.MAINNET:
          return "https://safe-transaction-mainnet.safe.global";
      case chainIds.POLYGON:
          return "https://safe-transaction-polygon.safe.global";
      case chainIds.RINKEBY:
          return "https://safe-transaction.rinkeby.gnosis.io";
      case chainIds.GOERLI:
          return "https://safe-transaction-goerli.safe.global";
      case chainIds.OPTIMISM:
          return "https://safe-transaction-optimism.safe.global";
      case chainIds.ARBITRUM:
          return "https://safe-transaction-arbitrum.safe.global";
      default:
          return "https://safe-transaction-goerli.safe.global";

  }
}


export function getGnosisRelayURL(chainId) {
  switch (chainId) {
    case chainIds.MAINNET:
      return "https://safe-relay.gnosis.io";
    case chainIds.RINKEBY:
      return "https://safe-relay.rinkeby.gnosis.io";
    case chainIds.GOERLI:
      return "https://safe-relay.goerli.gnosis.io";
    default:
      return "https://safe-relay.gnosis.io";
  }
}

export function getBlockExplorerURL(chainId) {
  switch (chainId) {
      case chainIds.MAINNET:
          return "https://etherscan.io";
      case chainIds.POLYGON:
          return "https://polygonscan.com";
      case chainIds.RINKEBY:
          return "https://rinkeby.etherscan.io";
      case chainIds.GOERLI:
          return "https://goerli.etherscan.io";
      case chainIds.OPTIMISM:
          return "https://explorer.optimism.io";
      case chainIds.ARBITRUM:
          return "https://arbiscan.io";
      default:
          return "https://goerli.etherscan.io";
  }
}

export function getNativeTokenSymbol(chainId) {
  switch (chainId) {
      case chainIds.MAINNET:
          return "ETH";
      case chainIds.POLYGON:
          return "MATIC";
      case chainIds.RINKEBY:
          return "ETH";
      case chainIds.GOERLI:
          return "gETH";
      case chainIds.OPTIMISM:
          return "OETH";
      case chainIds.ARBITRUM:
          return "AETH";
      default:
          return "ETH";
  }
}

export function getNativeTokenName(chainId) {
  switch (chainId) {
      case chainIds.MAINNET:
          return "ETHEREUM";
      case chainIds.POLYGON:
          return "MATIC";
      case chainIds.RINKEBY:
          return "ETHEREUM";
      case chainIds.GOERLI:
          return "Görli Ether";
      case chainIds.OPTIMISM:
          return "Ether";
      case chainIds.ARBITRUM:
          return "Ether";
      default:
          return "ETHEREUM";
  }
}

export function getRedirectUrl(chainId) {
  switch (chainId) {
    case chainIds.MAINNET:
        return "https://app.parcel.money";
    case chainIds.POLYGON:
        return "https://polygon.parcel.money";
    case chainIds.GOERLI:
        return "https://goerli.parcel.money";
    case chainIds.OPTIMISM:
        return "https://optimism.parcel.money/";
    case chainIds.ARBITRUM:
        return "https://arbitrum.parcel.money/";
    default:
        return "https://goerli.parcel.money";
}
}

// export const requiredNetworkName = process.env.NEXT_PUBLIC_NETWORK_NAME;

// export const networkId = chainIds[requiredNetworkName];

// export const rpcUrl = RPC_URLS[networkId];
