import styled from "@emotion/styled";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

import metmask from "public/svg/metamask.svg";
import fortmatic from "public/svg/fortmatic.svg";
import trezor from "public/svg/trezor.svg";
import ledger from "public/svg/ledger.svg";
import walletconnect from "public/svg/walletconnect.svg";

const HorizontalThreeDots = ({ size, color }) => (
  <svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.16667 0.333496C1.25 0.333496 0.5 1.0835 0.5 2.00016C0.5 2.91683 1.25 3.66683 2.16667 3.66683C3.08333 3.66683 3.83333 2.91683 3.83333 2.00016C3.83333 1.0835 3.08333 0.333496 2.16667 0.333496ZM13.8333 0.333496C12.9167 0.333496 12.1667 1.0835 12.1667 2.00016C12.1667 2.91683 12.9167 3.66683 13.8333 3.66683C14.75 3.66683 15.5 2.91683 15.5 2.00016C15.5 1.0835 14.75 0.333496 13.8333 0.333496ZM8 0.333496C7.08333 0.333496 6.33333 1.0835 6.33333 2.00016C6.33333 2.91683 7.08333 3.66683 8 3.66683C8.91667 3.66683 9.66667 2.91683 9.66667 2.00016C9.66667 1.0835 8.91667 0.333496 8 0.333496Z"
      fill="#25274F"
    />
  </svg>
);

const File = ({ size, color }) => (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 5.00016V12.9955C12.0006 13.083 11.984 13.1699 11.951 13.251C11.9181 13.3321 11.8695 13.4059 11.808 13.4683C11.7466 13.5306 11.6734 13.5802 11.5928 13.6143C11.5121 13.6484 11.4255 13.6662 11.338 13.6668H0.662C0.486542 13.6668 0.318261 13.5972 0.194131 13.4732C0.070001 13.3492 0.000176695 13.181 0 13.0055V0.99483C0 0.636829 0.298 0.333496 0.665333 0.333496H7.33333V4.3335C7.33333 4.51031 7.40357 4.67988 7.52859 4.8049C7.65362 4.92992 7.82319 5.00016 8 5.00016H12ZM12 3.66683H8.66667V0.335496L12 3.66683ZM3.33333 3.66683V5.00016H5.33333V3.66683H3.33333ZM3.33333 6.3335V7.66683H8.66667V6.3335H3.33333ZM3.33333 9.00016V10.3335H8.66667V9.00016H3.33333Z"
      fill="#636B81"
    />
  </svg>
);

const Link = ({ size, color }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.70684 4.40666L8.65017 5.34999C9.08356 5.78334 9.42735 6.29781 9.6619 6.86403C9.89645 7.43024 10.0172 8.03712 10.0172 8.64999C10.0172 9.26287 9.89645 9.86974 9.6619 10.436C9.42735 11.0022 9.08356 11.5166 8.65017 11.95L8.41417 12.1853C7.53896 13.0605 6.35191 13.5522 5.11417 13.5522C3.87643 13.5522 2.68938 13.0605 1.81417 12.1853C0.938956 11.3101 0.447266 10.1231 0.447266 8.88533C0.447266 7.64759 0.938956 6.46054 1.81417 5.58533L2.7575 6.52866C2.44575 6.83769 2.19811 7.20526 2.02879 7.61026C1.85947 8.01526 1.77182 8.44972 1.77086 8.88868C1.76989 9.32765 1.85565 9.76248 2.02319 10.1682C2.19073 10.574 2.43676 10.9426 2.74716 11.253C3.05756 11.5634 3.42621 11.8094 3.83194 11.977C4.23768 12.1445 4.67251 12.2303 5.11148 12.2293C5.55045 12.2283 5.9849 12.1407 6.3899 11.9714C6.7949 11.8021 7.16247 11.5544 7.4715 11.2427L7.7075 11.0067C8.33241 10.3816 8.68346 9.53387 8.68346 8.64999C8.68346 7.76611 8.33241 6.91842 7.7075 6.29332L6.76417 5.34999L7.7075 4.40733L7.70684 4.40666ZM12.1855 8.41399L11.2428 7.47132C11.5546 7.16229 11.8022 6.79472 11.9716 6.38972C12.1409 5.98472 12.2285 5.55027 12.2295 5.1113C12.2304 4.67234 12.1447 4.2375 11.9772 3.83176C11.8096 3.42603 11.5636 3.05738 11.2532 2.74698C10.9428 2.43658 10.5741 2.19055 10.1684 2.02301C9.76266 1.85547 9.32783 1.76972 8.88886 1.77068C8.44989 1.77164 8.01544 1.85929 7.61044 2.02861C7.20544 2.19793 6.83787 2.44557 6.52884 2.75733L6.29284 2.99333C5.66793 3.61842 5.31688 4.46611 5.31688 5.34999C5.31688 6.23387 5.66793 7.08157 6.29284 7.70666L7.23617 8.64999L6.29284 9.59266L5.35017 8.64999C4.91678 8.21665 4.57299 7.70218 4.33844 7.13596C4.10389 6.56974 3.98317 5.96287 3.98317 5.34999C3.98317 4.73712 4.10389 4.13024 4.33844 3.56403C4.57299 2.99781 4.91678 2.48334 5.35017 2.04999L5.58617 1.81466C6.46138 0.939444 7.64843 0.447754 8.88617 0.447754C10.1239 0.447754 11.311 0.939444 12.1862 1.81466C13.0614 2.68987 13.5531 3.87692 13.5531 5.11466C13.5531 6.3524 13.0614 7.53944 12.1862 8.41466L12.1855 8.41399Z"
      fill="#636B81"
    />
  </svg>
);

type Props = {
  name: string;
  onClick?: any;
  style?: any;
};

export const MUIIcons = ({ name, onClick, style }: Props) => {
  if (name === "info_outlined") return <InfoOutlinedIcon onClick={onClick} style={style} />;
  if (name === "close_outlined") return <CloseOutlinedIcon style={style} onClick={onClick} />;
  if (name === "file_upload_outlined")
    return <FileUploadOutlinedIcon style={style} onClick={onClick} />;
  return null;
};

export const IconBox: any = styled.div`
  display: flex;
  vertical-align: middle;
  align-items: center;
  margin-right: 5px;
`;

export const CustomIcons = ({ name, color = "", size = "" }) => {
  if (name === "file") return <File size={size} color={color} />;
  if (name === "horizontal_three_dots") return <HorizontalThreeDots size={size} color={color} />;
  if (name === "link") return <Link size={size} color={color} />;
  return null;
};

export const InvalidLink = () => (
  <svg
    width="133"
    height="132"
    viewBox="0 0 133 132"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="66.5" cy="66" r="40" fill="#FDDEDE" />
    <circle opacity="0.16" cx="66.5" cy="66" r="66" fill="#FCC2C2" />
    <path
      d="M44.5 52.1673C44.5 48.7612 47.2612 46 50.6673 46H72.1854H82.33C85.7376 46 88.5 48.7624 88.5 52.17V58.7636V78.8327C88.5 82.2388 85.7388 85 82.3327 85H50.6673C47.2612 85 44.5 82.2388 44.5 78.8327V52.1673Z"
      fill="white"
    />
    <path
      d="M45.045 52.1673C45.045 49.0622 47.5622 46.545 50.6673 46.545H72.1854H82.33C85.4366 46.545 87.955 49.0634 87.955 52.17V58.7636V78.8327C87.955 81.9378 85.4378 84.455 82.3327 84.455H50.6673C47.5622 84.455 45.045 81.9378 45.045 78.8327V52.1673Z"
      stroke="#EC5952"
      stroke-opacity="0.5"
      stroke-width="1.09"
    />
    <line x1="45.5" y1="52.5" x2="87.5" y2="52.5" stroke="#FDDEDE" />
    <circle cx="50.5" cy="50" r="1" fill="#F6ACA8" />
    <circle cx="54.5" cy="50" r="1" fill="#F6ACA8" />
    <circle cx="58.5" cy="50" r="1" fill="#F6ACA8" />
    <path
      d="M67.3831 64.7583L68.5622 65.9375C69.104 66.4792 69.5337 67.1223 69.8269 67.83C70.1201 68.5378 70.271 69.2964 70.271 70.0625C70.271 70.8286 70.1201 71.5872 69.8269 72.2949C69.5337 73.0027 69.104 73.6458 68.5622 74.1875L68.2672 74.4817C67.1732 75.5757 65.6894 76.1903 64.1422 76.1903C62.5951 76.1903 61.1112 75.5757 60.0172 74.4817C58.9232 73.3876 58.3086 71.9038 58.3086 70.3567C58.3086 68.8095 58.9232 67.3257 60.0172 66.2317L61.1964 67.4108C60.8067 67.7971 60.4971 68.2566 60.2855 68.7628C60.0739 69.2691 59.9643 69.8121 59.9631 70.3609C59.9619 70.9096 60.0691 71.4531 60.2785 71.9603C60.4879 72.4674 60.7955 72.9283 61.1835 73.3163C61.5715 73.7043 62.0323 74.0118 62.5394 74.2212C63.0466 74.4306 63.5902 74.5378 64.1389 74.5366C64.6876 74.5354 65.2306 74.4259 65.7369 74.2142C66.2431 74.0026 66.7026 73.693 67.0889 73.3033L67.3839 73.0083C68.165 72.227 68.6038 71.1673 68.6038 70.0625C68.6038 68.9576 68.165 67.898 67.3839 67.1167L66.2047 65.9375L67.3839 64.7592L67.3831 64.7583ZM72.9814 69.7675L71.8031 68.5892C72.1928 68.2029 72.5023 67.7434 72.714 67.2372C72.9256 66.7309 73.0352 66.1878 73.0364 65.6391C73.0376 65.0904 72.9304 64.5469 72.721 64.0397C72.5115 63.5325 72.204 63.0717 71.816 62.6837C71.428 62.2957 70.9672 61.9882 70.46 61.7788C69.9528 61.5693 69.4093 61.4621 68.8606 61.4633C68.3119 61.4645 67.7688 61.5741 67.2626 61.7858C66.7563 61.9974 66.2969 62.307 65.9106 62.6967L65.6156 62.9917C64.8344 63.773 64.3956 64.8326 64.3956 65.9375C64.3956 67.0423 64.8344 68.102 65.6156 68.8833L66.7947 70.0625L65.6156 71.2408L64.4372 70.0625C63.8955 69.5208 63.4658 68.8777 63.1726 68.1699C62.8794 67.4622 62.7285 66.7036 62.7285 65.9375C62.7285 65.1714 62.8794 64.4128 63.1726 63.705C63.4658 62.9973 63.8955 62.3542 64.4372 61.8125L64.7322 61.5183C65.8262 60.4243 67.3101 59.8097 68.8572 59.8097C70.4044 59.8097 71.8882 60.4243 72.9822 61.5183C74.0762 62.6123 74.6909 64.0961 74.6909 65.6433C74.6909 67.1905 74.0762 68.6743 72.9822 69.7683L72.9814 69.7675Z"
      fill="#F44040"
    />
  </svg>
);

export const ConfirmationCircle = () => (
  <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="40.5" cy="40" r="40" fill="#F5F8FE" />
    <path
      d="M37.9999 43.9649L49.4899 32.4736L51.2587 34.2411L37.9999 47.4999L30.0449 39.5449L31.8124 37.7774L37.9999 43.9649Z"
      fill="#2962EF"
    />
  </svg>
);
export const WarningCircle = () => (
  <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="40.5" cy="40" r="40" fill="#FEFBF7" />
    <path
      d="M45.42 28.125L52.375 35.0838V44.9213L45.42 51.875H35.5825L28.625 44.92V35.0825L35.5825 28.125H45.42ZM44.3838 30.625H36.6175L31.1262 36.1188V43.885L36.6175 49.3775H44.3838L49.8762 43.885V36.1175L44.3838 30.6262V30.625ZM39.25 43.75H41.75V46.25H39.25V43.75ZM39.25 33.75H41.75V41.25H39.25V33.75Z"
      fill="#F1A252"
    />
  </svg>
);
export const selectWalletIcon = {
  MetaMask: metmask,
  Ledger: ledger,
  Trezor: trezor,
  Fortmatic: fortmatic,
  WalletConnect: walletconnect,
};
