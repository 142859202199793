import styled from "@emotion/styled";

export const BoxWrapper: any = styled.div`
  @media (max-width: 509px) {
    flex: 1 0 50%;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 20px 20px;

  background: #ffffff;

  border-radius: 8px;
  font-family: Metropolis;
`;

export const ErrorMsg: any = styled.div`
  margin-top: 34px;
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  color: #ec5952;
`;
