import Onboard from "bnc-onboard";
import { RPC_URLS } from "./networks";

const rpcUrl = networkId => RPC_URLS[networkId];
const dappId = process.env.NEXT_PUBLIC_BLOCKNATIVE_API_KEY;

export function initOnboard(subscriptions, networkId) {
  const r = rpcUrl(networkId);
  return Onboard({
    dappId,
    hideBranding: false,
    networkId,
    // apiUrl,
    // darkMode: true,
    subscriptions,
    walletSelect: {
      wallets: [
        { walletName: "metamask" },
        {
          walletName: "trezor",
          appUrl: "https://reactdemo.blocknative.com",
          email: "aaron@blocknative.com",
          rpcUrl: r,
        },
        {
          walletName: "walletConnect",
          infuraKey: process.env.NEXT_PUBLIC_INFURA_TOKEN,
          display: {
            desktop: true,
          },
          // as stated in the documentation, `infuraKey` is not mandatory if rpc is provided
          //networkId,
          // bridge: 'https://safe-walletconnect.gnosis.io/',
        },
        {
          walletName: "ledger",
          rpcUrl: r,
        },
        { walletName: "coinbase" },
        { walletName: "fortmatic", apiKey: "pk_test_886ADCAB855632AA" },
      ],
    },
    walletCheck: [
      { checkName: "derivationPath" },
      { checkName: "connect" },
      { checkName: "accounts" },
      { checkName: "network" },
      { checkName: "balance", minimumBalance: "0" },
    ],
  });
}
