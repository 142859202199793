import Box from "@mui/material/Box";

import { InvalidLink } from "src/components/Icons";
import { BoxWrapper, ErrorMsg } from "./styles";

export default function InvalidModal({ errorMsg }) {
  return (
    <Box maxWidth="509px" margin="10% auto">
      <BoxWrapper>
        <InvalidLink />
        <ErrorMsg>{errorMsg}</ErrorMsg>
      </BoxWrapper>
    </Box>
  );
}
