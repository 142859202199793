import { createTheme, responsiveFontSizes } from "@mui/material/styles";

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: "'Metropolis' ,sans-serif",
  },

  background_color: {
    main: "#ffffff",
    light: "#F7F7F7",
    selected: "#EAEFFD",
    lightprimary: "#EEF2FE",
  },
  border_color: {
    main: "#D6D5DF",
    primary: "#2962ef1a",
  },
  table: {
    headerbg: "#FAFAFA",
    headertext: "#828487",
    columnText: "#9998A4",
    borderColor: "#eaeaea",
    checkBoxColor: "#CAC9D6",
  },
  textbox: {
    border: "#E4E4EA",
  },
  palette: {
    background: {
      default: "#F8F8FA",
    },
    primary: {
      main: "#2962EF",
    },
    secondary: {
      main: "#6BBC93",
    },
    error: {
      main: "#EE365A",
    },
    success: {
      main: "#6DCA9B",
      contrastText: "#ffffff",
    },
    title: {
      main: "#25274F",
      sub: "#5E5C6C",
      light: "#8B91A1",
    },
    border_color: {
      main: "#d6d5df",
    },
  },

  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          textTransform: "none",
        },
      },
    },
  },
});

//Add the type of  new color to the Palette or theme here

declare module "@mui/material/styles" {
  interface Theme {
    background_color: {
      main: React.CSSProperties["color"];
      light: React.CSSProperties["color"];
      selected: React.CSSProperties["color"];
      lightprimary: React.CSSProperties["color"];
    };
    border_color: {
      main: React.CSSProperties["color"];
      primary: React.CSSProperties["color"];
    };
    table: {
      headerbg: React.CSSProperties["color"];
      headertext: React.CSSProperties["color"];
      columnText: React.CSSProperties["color"];
      checkBoxColor: React.CSSProperties["color"];
      borderColor: React.CSSProperties["color"];
    };
    textbox: {
      border: React.CSSProperties["color"];
    };
  }
  interface Palette {
    title: Palette["primary"];
  }
  interface PaletteOptions {
    title: PaletteOptions["primary"];
    border_color: PaletteOptions["primary"];
  }
  interface PaletteColor {
    sub?: string;
  }
  interface SimplePaletteColorOptions {
    sub?: string;
    light?: string;
  }
  interface ThemeOptions {
    background_color: {
      main: React.CSSProperties["color"];
      light: React.CSSProperties["color"];
      selected: React.CSSProperties["color"];
      lightprimary: React.CSSProperties["color"];
    };
    border_color: {
      main: React.CSSProperties["color"];
      primary: React.CSSProperties["color"];
    };
    textbox: {
      border: React.CSSProperties["color"];
    };
    table: {
      headerbg: React.CSSProperties["color"];
      headertext: React.CSSProperties["color"];
      columnText: React.CSSProperties["color"];
      checkBoxColor: React.CSSProperties["color"];
      borderColor: React.CSSProperties["color"];
    };
  }
}

export default responsiveFontSizes(theme);
