import { useEffect, useState, createContext, useContext } from "react";
import { ethers } from "ethers";
import { Web3Provider } from "@ethersproject/providers";
import { getAddress } from "@ethersproject/address";
import { EthersAdapter } from "@gnosis.pm/safe-core-sdk";

import { initOnboard } from "src/helpers/initOnBoard";
import { InviteLinkContext } from "src/contexts/inviteLinkContext";
import Apis from "src/apis/inviteLink";

// import { networkId } from "src/helpers/networks";

interface BaseAppContextInterface {
  web3: any;
}
export const BaseContext = createContext({
  web3: {},
} as BaseAppContextInterface);

export function BaseContextProvider({ children }) {
  const [address, setAddress] = useState(null);
  const [network, setNetwork] = useState(null);
  const [balance, setBalance] = useState(null);
  const [wallet, setWallet] = useState<any>({});
  const [provider, setProvider] = useState(null);
  const [ethersAdapter, setEthersAdapter] = useState(null);
  const { networkId, inviteId, setContributorError, inviteLinkData }: any =
    useContext(InviteLinkContext);

  // const [isLoggedIn, setIsLoggedIn] = useState(null);
  // const [isPrevWallet, setIsPrevWallet] = useState(null);
  const [onboard, setOnboard] = useState(null);
  // const [safeService, setSafeService] = useState(null);
  const [isNetworkChanged, setIsNetworkChanged] = useState(false);
  const [isContractWallet, setIsContractWallet] = useState(false);
  const [isGnosisWallet, setIsGnosisWallet] = useState(false);
  // const {
  //     data,
  //     isError,
  //     refetch: refetchAuth,
  // } = useCheckAuth({
  //     refetchOnWindowFocus: false,
  //     enabled: false,
  // });
  // const [ownedSafes, setOwnedSafes] = useState([]);
  // const [contributedSafes, setContributedSafes] = useState([]);
  // const { refetch: refetchDisconnect } = useDisconnect({
  //     refetchOnWindowFocus: false,
  //     enabled: false,
  // });
  // const [darkMode, setDarkMode] = useState(false)
  // const [desktopPosition, setDesktopPosition] = useState('bottomRight')
  // const [mobilePosition, setMobilePosition] = useState('top')

  const handleResetBaseContext = () => {
    setAddress(null);
    setBalance(null);
    setWallet({});
    setProvider(null);
    setEthersAdapter(null);
    setOnboard(null);
    setIsNetworkChanged(null);
    setIsContractWallet(false);
    setIsGnosisWallet(false);
  };

  useEffect(() => {
    // const safeService = new SafeServiceClient(transactionServiceUrl);
    // setSafeService(safeService);
    const onboard = initOnboard(
      {
        address: handleAddress,
        network: handleNetwork,
        balance: setBalance,
        wallet: wallet => {
          setWallet(wallet);

          if (wallet.provider) {
            // console.info(`${wallet.name} is connected`);

            const ethersProvider = new Web3Provider(wallet.provider);
            // console.log(ethersProvider.getSigner());
            // signerAddress(ethersProvider.getSigner());
            const ethersAdapter = new EthersAdapter({
              ethers: ethers,
              signer: ethersProvider.getSigner(0),
            });
            setEthersAdapter(ethersAdapter);
            // provider = ethersProvider;
            setProvider(ethersProvider);

            // window.localStorage.setItem("selectedWallet", wallet.name);
          } else {
            // provider = null;

            setProvider(false);
            setWallet({});
          }
        },
      },
      networkId,
    );

    // if (window.localStorage.getItem("selectedWallet")) {
    //     setIsPrevWallet(true);
    // } else {
    //     setIsPrevWallet(false);
    // }
    setOnboard(onboard);
  }, [networkId]);

  useEffect(() => {
    if (address && inviteId && inviteLinkData?.inviteType === "addContributor") {
      Apis.checkContributor({
        walletAddress: address,
        inviteId: inviteId,
      }).then(res => {
        if (res.data.isAlreadyContributor)
          setContributorError("This wallet address is already a contributor");
        else setContributorError("none");
      });
    }
  }, [address, inviteLinkData]);

  useEffect(() => {
    if (network && wallet && wallet.provider) {
      const ethersProvider = new Web3Provider(wallet.provider);
      const ethersAdapter = new EthersAdapter({
        ethers: ethers,
        signer: ethersProvider.getSigner(0),
      });

      setEthersAdapter(ethersAdapter);
      setProvider(ethersProvider);
    }
  }, [network]);

  // useEffect(() => {
  //   const previouslySelectedWallet = window.localStorage.getItem("selectedWallet");
  //   if (onboard && previouslySelectedWallet) {
  //     onboard.walletSelect(previouslySelectedWallet);
  //   }
  // }, [onboard]);

  // useEffect(() => {
  //     if (provider && address) {
  //         refetchAuth();
  //     }
  // }, [provider, address]);

  // useEffect(() => {
  //     if (isError) {
  //         // console.log(error, "Here");
  //         // setIsLoggedIn(false);

  //         return;
  //     }

  //     if (data) {
  //         // setIsLoggedIn(true);
  //         setOwnedSafes(data.data.me.ownedSafes);
  //         setContributedSafes(data.data.me.contributedSafes);
  //     }
  // }, [data, isError]);

  const handleAddress = async account => {
    setAddress(() => {
      // console.log(prev, account, "from acc");
      // if (prev === null || prev === undefined) return account;
      // if (prev === account) return account;
      // window.localStorage.removeItem("selectedWallet");
      // window.localStorage.removeItem("parcelv2_lastLoggedInSafeAddress");

      // refetchDisconnect().then(() => {
      //     // setIsLoggedIn(false);
      //     router.push("/");
      // });
      return account;
    });
    setAddress(account);
  };
  const handleNetwork = async network => {
    if (networkId !== network) {
      setIsNetworkChanged(true);
      return;
    }
    setIsNetworkChanged(false);

    setNetwork(() => {
      // console.log(prev, account, "from acc");
      // if (prev === null || prev === undefined) return network;
      // if (prev === network) return network;
      // window.localStorage.removeItem("selectedWallet");
      // window.localStorage.removeItem("parcelv2_lastLoggedInSafeAddress");
      // refetchDisconnect().then(() => {
      //     // setIsLoggedIn(false);
      //     router.push("/");
      // });
      return network;
    });
    setNetwork(network);
  };

  return (
    <BaseContext.Provider
      value={{
        web3: {
          onboard,
          account: address ? getAddress(address) : undefined,
          chainId: network,
          library: provider,
          ethersAdapterOwner: ethersAdapter,
          connector: wallet,
          balance: balance,
          active: address && balance ? true : false,

          // isLoggedIn,
          // setIsLoggedIn,
          // isPrevWallet,
          // setIsPrevWallet,
          // safeService,
          // ownedSafes,
          // refetchAuth,
          isNetworkChanged,
          setIsNetworkChanged,
          // contributedSafes,
          isContractWallet,
          setIsContractWallet,
          handleResetBaseContext,
          isGnosisWallet,
          setIsGnosisWallet,
        },
      }}
    >
      {children}
    </BaseContext.Provider>
  );
}

export const useBaseContext = () => {
  const context = useContext(BaseContext);
  return { web3: context.web3 };
};
