import React, { useCallback, useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import copy from "copy-to-clipboard";

export default function CopyOnClick({ children, value, text, ...passThrough }) {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    let timer;
    if (copied) {
      timer = setTimeout(() => setCopied(false), 1500);
    }

    return () => clearTimeout(timer);
  }, [copied]);

  const onClickCopy = useCallback(
    event => {
      event.stopPropagation();
      copy(value);
      setCopied(true);
    },
    [value],
  );

  return (
    <div {...passThrough}>
      <Tooltip
        title={copied ? `Copied ` : `${text ? text : "Click to Copy"}`}
        placement="bottom-start"
      >
        <div onClick={onClickCopy} {...passThrough}>
          {children}
        </div>
      </Tooltip>
    </div>
  );
}
