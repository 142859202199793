import { useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Image from "next/image";
import { useRouter } from "next/router";

import OnBoardButton from "src/components/Buttons/OnBoardButton";
import { useRouteChange } from "src/hooks";
import { useBaseContext } from "src/contexts/baseContext";
import metmask from "../public/svg/metamask.svg";
import fortmatic from "../public/svg/fortmatic.svg";
import trezor from "../public/svg/trezor.svg";
import ledger from "../public/svg/ledger.svg";
import walletconnect from "../public/svg/walletconnect.svg";

export default function Home() {
  const { onboard } = useBaseContext().web3;
  const [selectedWallet, setSelectedWallet] = useState();
  const { pageLoading } = useRouteChange();
  const router = useRouter();

  const selectWallet = async wallet => {
    if (pageLoading) {
      return;
    }
    setSelectedWallet(wallet);
    await onboard.walletSelect(wallet);
    await onboard.walletCheck(wallet);
  };

  return (
    <Box maxWidth="433px" margin="10% auto">
      <Typography fontSize="1.5rem" fontWeight="700" color="title.main" textAlign="left">
        Welcome to Parcel
      </Typography>
      <Typography fontSize="14px" color="#5E5C6C">
        Connect your wallet to get started
      </Typography>
      <Stack direction="column" justifyContent="flex-start" spacing={2} mt={3}>
        <LoadingButton
          variant="contained"
          onClick={() => selectWallet("MetaMask")}
          sx={{
            height: "41px",
            fontSize: "1rem",
            textTransform: "none",
          }}
          startIcon={<Image src={metmask} alt="metamask icon" priority />}
          loading={pageLoading && selectedWallet == "MetaMask"}
        >
          Metamask
        </LoadingButton>
        <OnBoardButton
          variant="contained"
          onClick={() => selectWallet("Fortmatic")}
          sx={{
            height: "41px",
          }}
          loading={pageLoading && selectedWallet == "Fortmatic"}
          startIcon={
            <Image src={fortmatic} alt="fortmatic icon" height="14px" width="14px" priority />
          }
        >
          Formatic
        </OnBoardButton>
        <OnBoardButton
          variant="contained"
          onClick={() => selectWallet("WalletConnect")}
          sx={{
            height: "41px",
          }}
          loading={pageLoading && selectedWallet == "WalletConnect"}
          startIcon={
            <Image
              src={walletconnect}
              alt="walletconnect icon"
              height="14px"
              width="15px"
              priority
            />
          }
        >
          Wallet Connect
        </OnBoardButton>
        <OnBoardButton
          variant="contained"
          onClick={() => selectWallet("Ledger")}
          sx={{
            height: "41px",
          }}
          loading={pageLoading && selectedWallet == "Ledger"}
          startIcon={<Image src={ledger} alt="ledger icon" height="14px" width="14px" priority />}
        >
          Ledger
        </OnBoardButton>
        <OnBoardButton
          variant="contained"
          onClick={() => selectWallet("Trezor")}
          sx={{
            height: "41px",
          }}
          loading={pageLoading && selectedWallet == "Trezor"}
          startIcon={<Image src={trezor} alt="trezor icon" height="14px" width="14px" priority />}
        >
          Trezor
        </OnBoardButton>
      </Stack>
    </Box>
  );
}
