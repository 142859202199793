import { useEffect, useState, createContext } from "react";

import { getTokenPriceBySafe } from "src/apis/safe";
import { nativeTokenSymbol, nativeTokenName, NATIVE_ICON_URL } from "src/constants";

export const InviteLinkContext = createContext({});

export function InviteLinkProvider({ children }) {
  const [inviteLinkData, setInviteLinkData] = useState(null);
  const [inviteId, setInviteId] = useState(null);
  const [error, setError] = useState(null);
  const [networkId, setNetworkId] = useState(1);
  const [contributorError, setContributorError] = useState("");

  const [tokensInSafe, setTokensInSafe] = useState({
    [nativeTokenSymbol(networkId)]: {
      name: nativeTokenName(networkId),
      symbol: nativeTokenSymbol(networkId),
      logoUri: NATIVE_ICON_URL(networkId),
      decimals: 18,
      tokenAddress: nativeTokenSymbol(networkId),
      fiatConversion: 1,
    },
  });

  const handleResetInviteLink = () => {
    setInviteLinkData(null);
    setError(null);
    setTokensInSafe({
      [nativeTokenSymbol(networkId)]: {
        name: nativeTokenName(networkId),
        symbol: nativeTokenSymbol(networkId),
        logoUri: NATIVE_ICON_URL(networkId),
        decimals: 18,
        tokenAddress: nativeTokenSymbol(networkId),
        fiatConversion: 1,
      },
    });
  };

  const [whitelistedTokens, setWhitelistedTokens] = useState([]);
  const [allPaymentTypes, setAllPaymentTypes] = useState([]);

  useEffect(() => {
    if (inviteLinkData?.safeAddress) {
      const filteredCategories = inviteLinkData?.whitelistedCategories
        .filter(({ isEnabled }) => Boolean(isEnabled))
        ?.map(({ name }) => name);
      setWhitelistedTokens(inviteLinkData?.whitelistedTokens);
      setAllPaymentTypes(filteredCategories);
      getTokenPriceBySafe(inviteLinkData.safeAddress, networkId)
        .then(res => {
          let tokenData = res?.data?.map(token => {
            if (token.token) {
              return {
                ...token.token,
                tokenAddress: token.tokenAddress,
                fiatConversion: Number(token.fiatConversion),
              };
            } else {
              return {
                name: nativeTokenName(networkId),
                symbol: nativeTokenSymbol(networkId),
                logoUri: NATIVE_ICON_URL(networkId),
                decimals: 18,
                tokenAddress: nativeTokenSymbol(networkId),
                fiatConversion: Number(token.fiatConversion),
              };
            }
          });

          let result = tokenData.reduce((agg, tokenItem) => {
            agg[tokenItem.tokenAddress] = tokenItem;
            return agg;
          }, {});
          setTokensInSafe(result);
        })
        .catch(err => {
          setTokensInSafe({
            [nativeTokenSymbol(networkId)]: {
              name: nativeTokenName(networkId),
              symbol: nativeTokenSymbol(networkId),
              logoUri: NATIVE_ICON_URL(networkId),
              decimals: 18,
              tokenAddress: nativeTokenSymbol(networkId),
              fiatConversion: 1,
            },
          });
        });
    }
  }, [inviteLinkData?.safeAddress]);

  return (
    <InviteLinkContext.Provider
      value={{
        setInviteLinkData,
        inviteLinkData,
        inviteId,
        setInviteId,
        error,
        setError,
        tokensInSafe,
        handleResetInviteLink,
        networkId,
        setNetworkId,
        setContributorError,
        contributorError,
        whitelistedTokens,
        allPaymentTypes,
      }}
    >
      {children}
    </InviteLinkContext.Provider>
  );
}
